<template lang="pug">
  table#commissions.table.table-xss.table-hover.border-bottom
    tbody
      tr
        td Ürün
        td.text-center Yabancı müşteri
        td.text-center Yerli müşteri
        td.text-center Arap müşteri
      tr
        td HEPSİ
        td: el-input.pl-20(type="number" v-model="allForeign" @input="setAllCommission('foreign', $event)")
        td: el-input.pl-20(type="number" v-model="allLocal" @input="setAllCommission('local', $event)")
        td: el-input.pl-20(type="number" v-model="allOther" @input="setAllCommission('other', $event)")
      tr(v-for="c in categories" :key="c.id")
        td(style="width: 40%") {{ c.name }}
        td: el-input.pl-20(type="number" :value="getCommission(c.id, 'foreign')" @input="setCommission(c.id, 'foreign', $event)")
        td: el-input.pl-20(type="number" :value="getCommission(c.id, 'local')" @input="setCommission(c.id, 'local', $event)")
        td: el-input.pl-20(type="number" :value="getCommission(c.id, 'other')" @input="setCommission(c.id, 'other', $event)")
</template>
<script type="text/javascript">
import ProductCategory from '@/models/ProductCategory'
export default {
  name: 'entity-commissions',
  props: ['value'],
  data(){
    return {
      allForeign: 0,
      allLocal: 0,
      allOther: 0,
      categories: [],
      commissions: []
    }
  },
  beforeDestroy() {
    this.allForeign = 0
    this.allLocal = 0
    this.allOther = 0
    this.commissions = []
  },
  created(){
    ProductCategory.get().then(data => {
      this.categories = data.filter(c => c.parent_id === null)
    })
  },
  methods: {
    getCommission(category, type){
      let c = this.commissions.find(c => c.category_id === category)
      return c && c[type]
    },
    setCommission(category, type, rate){
      let i = this.commissions.findIndex(c => c.category_id === category)
      if(i === -1){
        this.commissions.push({ category_id: category, [type]: rate })
      }else{
        this.$set(this.commissions[i], type, rate)
      }
    },
    setAllCommission(type, rate){
      this.categories.forEach(c => {
        this.setCommission(c.id, type, rate)
      })
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function(){
        this.commissions = this.value
      }
    }
  }
}
</script>
<style type="text/css">
  #commissions td input[type="number"]{
    text-align: left;
  }
</style>
