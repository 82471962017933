<template lang="pug">
  div
    el-alert(
      title="Bu bölümü görüntüleme yetkiniz yok"
      v-if="!can('agencies.list')"
      :closable="false"
      type="error"
      show-icon
      center
    )
    el-card(body-style="padding:0" v-if="can('agencies.list')")
      div(slot="header")
        el-form(inline)
          el-form-item
            el-button-group
              el-button(type="primary" @click="create" icon="el-icon-plus" :disabled="!can('agencies.create')") Oluştur
              el-button(type="primary" @click="fetch(false)" icon="el-icon-download" :disabled="!can('agencies.list')") Excel
      el-input(placeholder="Acenta ara" v-model="search")
        i.el-input__icon.el-icon-search(slot="prefix")
      el-table(
        :data="filteredAgencies"
        size="mini"
        @row-dblclick="edit"
        :height="$store.state.windowHeight-192"
        v-if="can('agencies.list')"
      )
        el-table-column(type="index" width="30px" class-name="text-muted" align="right")
        el-table-column(prop="name" label="Acenta" sortable show-overflow-tooltip)
        el-table-column(prop="phone" label="Telefon" sortable)
        el-table-column(prop="email" label="Email" sortable show-overflow-tooltip)
        el-table-column(prop="address" label="Adres" sortable show-overflow-tooltip)
        el-table-column(label="KOMİSYONLAR" align="center" class-name="text-primary")
          el-table-column(class-name="text-primary" align="right" width="125" v-for="c in categories" :key="c.id" prop="commissions" :label="c.name")
            template(v-slot="props") {{ getCommission(props.row.id, c.id) }}
        el-table-column(prop="margin" label="OKP, %" sortable class-name="text-danger" width="120" align="right")
          template(v-slot="props") {{ props.row.margin }} %
        el-table-column(prop="driver_payment" label="Şoför ödemesi" sortable width="120" align="right")
          template(v-slot="props") {{ props.row.driver_payment }} {{ props.row.dp_currency_id }}
        el-table-column(prop="id" label="İşlem" align="center")
          template(v-slot="props")
            el-button-group
              el-button.micro(plain @click="edit(props.row)"): span.el-icon-edit
              el-button.micro(plain @click="remove(props.row.id)"): span.el-icon-delete.text-danger
    el-drawer(:visible.sync="modal" size="600px")
      span.text-center(slot="title") {{ agency.name }}
      el-form.pt-20.pr-20.pl-20(label-position="right" label-width="130px")
        el-form-item(label="Acenta")
          el-input(v-model="agency.name")
        el-form-item(label="Telefon")
          el-input(v-model="agency.phone")
        el-form-item(label="E-mail")
          el-input(v-model="agency.email")
        el-form-item(label="Adres")
          el-input(v-model="agency.address")
        el-form-item(label="Devir")
          el-input(type="number" v-model.number="agency.account_transfer")
        el-form-item(label="Opr. katkı payı")
          el-input(type="number" v-model.number="agency.margin")
            template(slot="append") %
        el-form-item(label="Şoför ödemesi")
          el-input(type="number" v-model.number="agency.driver_payment")
            template(slot="append")
              el-select(v-model="agency.dp_currency_id" filterable clearable)
                el-option(v-for="c in $store.state.currencies" :key="c" :value="c" :label="c")
      el-divider: span.text-primary Komisyon oranları, %
      entity-commissions(v-model="agency.commissions" :key="`agency-${agency.id}`")
      center.pt-20: el-button(@click="save" :loading="saving" icon="el-icon-check" :disabled="!saveable") Kaydet
</template>
<script>
import get from 'lodash/get'
import ProductCategory from '@/models/ProductCategory'
import Company from '@/models/Company'
import EntityCommissions from '@/components/EntityCommissions.vue'
export default {
  name: 'agencies',
  components: { EntityCommissions },
  data(){
    return {
      fetching: false,
      saving: false,
      modal: false,
      search: '',
      categories: [],
      agency: new Company({
        name: '',
        type: 'agency',
        phone: '',
        email: '',
        address: '',
        margin: 0,
        driver_payment: 0,
        dp_currency_id: 'USD',
        account_transfer: 0,
        commissions: []
      }),
      agencies: []
    }
  },
  async created(){
    if(this.can('agencies.list')){
      this.categories = await ProductCategory.get()
      this.fetch()
    }
  },
  methods: {
    async fetch(excel) {
      try {
        this.fetching = true
        this.agencies = await Company.include('commissions').where('type', 'agency').get()
        this.fetching = false
      } catch({ response }) {
        this.$message.error(response.data.message)
        this.fetching = false
      }
    },
    create() {
      this.agency = new Company({
        name: '',
        type: 'agency',
        phone: '',
        email: '',
        address: '',
        margin: 0,
        driver_payment: 0,
        dp_currency_id: 'USD',
        account_transfer: 0,
        commissions: []
      })
      this.modal = true
    },
    async edit({ id }) {
      this.agency = await Company.include('commissions').find(id)
      this.modal = true
    },
    async save() {
      try{
        this.saving = true
        await this.agency.save()
        this.saving = false
        this.modal = false
        this.fetch()
      }catch(e){
        this.saving = false
        this.$message.error(e.message)
      }
    },
    async remove(id) {
      await this.$confirm('Kayıt silinmesine onay veriniz', 'Uyarı', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Vazgeç',
        type: 'warning',
        center: true
      })
      try {
        let { data } = await window.axios.delete('/companies/' + id)
        this.fetch(false)
        if (data.deleted) {
          this.$message({
            type: 'success',
            message: 'Kayıt silinmiştir',
            center: true,
            closable: true
          })
        }
      } catch(e) {
        this.$message({
          type: 'error',
          message: 'Kayıt silinemedi',
          center: true,
          closable: false
        })
      }
    },
    getCommission(id, category_id){
      let model = this.agencies.find(a => a.id === id)
      let comm = model.commissions.find(c => c.category_id === category_id)
      return get(comm, 'foreign', 0)
    }
  },
  computed: {
    filteredAgencies: function(){
      return this.agencies.filter(a => a.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    saveable: function(){
      return this.agency.name && this.agency.type === 'agency' && this.agency.margin >= 0 && this.agency.driver_payment >= 0 && this.agency.dp_currency_id
    }
  }
}
</script>
